<template>
  <div class="reportTemplate">
    <div class="padL15 padB15 imageHolder">
      <div
          v-if="result.report.editable==false"
          class="draggablearea"
          style="max-width:525px;min-width:525px;height:45px!important;max-height:45px !important;"
      >
        <div class="imageplace draggable" style="position: relative;">
          <img style="display:inline-block;height:45px;max-width:524.5px;" class="resizable" src/>
        </div>
      </div>

      <div v-if="result.report.editable==true">
        <v-card>
          <v-card-title>
            <h4 class="rtTitles">{{ languagePack('reportTemplates', 'headerImage') }}</h4>
          </v-card-title>
          <v-card-text>
            <div class="contentAlign">
              {{ languagePack('reportTemplates', 'headerText') }}
              <br/> {{ languagePack('reportTemplates', 'labelHeaderImgFormat') }}
              <br/> {{ languagePack('reportTemplates', 'labelHeaderImgSize') }}
            </div>
            <fileReader
                v-model="file"
                @load="validateImage(...arguments)"
                :fieldAttributes="{fieldAttrInput:{'placeholder':languagePack('reportTemplates','browseButton')}}"
            ></fileReader>
            <span
                style="position: absolute;font-size: 12px;top:-9px;"
            >{{ languagePack('reportTemplates', 'imgeConditions') }}</span>
            <div class="imageHolder">
              <div
                  class="imageplace required ml-2"
                  style="position: relative; border: 1px solid rgba(128, 128, 128, 0.7); height: 48px; max-width: 99%"
              >
                <img
                    style="display:inline-block;max-height:45px;"
                    status="none"
                    class="sourceHeaderImg required"
                    :src="imageSource"
                />
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <div class="padL15 timeFormatter" style="margin-top: 0.3em">
      <div>
        <v-card>
          <v-card-title>
            <h4 class="rtTitles">{{ languagePack('reportTemplates', 'timeFormatHeader') }}</h4>
          </v-card-title>
          <v-card-subtitle>
            {{ languagePack('reportTemplates', 'timeContent') }}
            <dateFormatInfo></dateFormatInfo>
          </v-card-subtitle>
          <v-card-text>
            <div>
              <div>
                <momentDateFormatter
                    :result="result"
                    v-model="timeformat"
                ></momentDateFormatter>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <div class="ui-widget-content padB15" style="margin-top: 0.3em;">
      <v-card>
        <v-card-title>
          <h4 class="rtTitles padL15">{{ languagePack('reportTemplates', 'additionalInfo') }}</h4>
        </v-card-title>
        <v-card-text>
          <div v-if="result.report.editable==true">
            <primaryTextfield
                v-model="headTitle"
                :fieldAttrInput="{}"
                :label="languagePack('reportTemplates','headerTitle')"
            ></primaryTextfield>
          </div>
          <div style=" width:34.7em;margin-left: 15px">
            <table class="reportDiscFields" style="width:100%;margin:5px 5px 5px 5px;">
              <tbody class="sortable"></tbody>
            </table>
            <reportAdditionalData :reportTemplatevalue="reportTemplatevalue" v-on="$listeners"></reportAdditionalData>
          </div>

          <div v-if="result.report.editable==true">
            <v-card outlined class="mt-3">
              <v-card-title>
                <h4 class="rtTitles padL15">{{ languagePack('reportTemplates', 'includeStatus') }}</h4>
              </v-card-title>
              <v-card-subtitle>
                <div class="contentAlign padL15">{{ languagePack('reportTemplates', 'statusContent') }}</div>
              </v-card-subtitle>
              <v-card-text>
                <checkbox
                    :fieldAttrInput="{}"
                    :fieldAttributes="{name:'Result Error'}"
                    :value="include.resultError"
                    @input="updateInclude('resultError',...arguments)"
                    :hideDetails="true"
                ></checkbox>
                <checkbox
                    :fieldAttrInput="{}"
                    :fieldAttributes="{name:'Result Warning'}"
                    :value="include.resultWarning"
                    @input="updateInclude('resultWarning',...arguments)"
                    :hideDetails="true"
                ></checkbox>
                <checkbox
                    :fieldAttrInput="{}"
                    :fieldAttributes="{name:'Processing Error'}"
                    :value="include.processingError"
                    @input="updateInclude('processingError',...arguments)"
                    :hideDetails="true"
                ></checkbox>
                <checkbox
                    :fieldAttrInput="{}"
                    :fieldAttributes="{name:'Processing Warning'}"
                    :value="include.processingWarning"
                    @input="updateInclude('processingWarning',...arguments)"
                    :hideDetails="true"
                ></checkbox>
                <checkbox
                    :fieldAttrInput="{}"
                    :fieldAttributes="{name:'OK'}"
                    :value="include.ok"
                    @input="updateInclude('ok',...arguments)"
                    :hideDetails="true"
                ></checkbox>
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <noticeDialog v-model="showDialog" title="Notice" :message="message"></noticeDialog>
  </div>
</template>
<script>
import dateFormatInfo from "@/components/legacy/dateFormatInfo.vue";
import reportAdditionalData from "@/components/legacy/reportAdditionalData.vue";
import noticeDialog from "@/commonComponents/noticeDialog.vue";
import fileReader from "@/commonComponents/fileReader.vue";
import momentDateFormatter from "@/components/legacy/momentDateFormatter.vue";
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
import lineButtons from "@/cfg/lineButtons.json";

import checkbox from "@/commonComponents/checkbox.vue";
import requests from '@/js/requests';

export default {
  props: ["result", "value"],
  data() {
    return {
      file: null,
      message: "",
      showDialog: false,
      imageSource: "",

      fields: {
        name: {
          type: "text",
          name: "Attribute",
        },
        value: {
          type: "text",
          name: "Value",
        },
        remove: lineButtons.remove,
      },
    };
  },
  components: {
    dateFormatInfo,
    fileReader,
    noticeDialog,
    momentDateFormatter,
    primaryTextfield,
    checkbox,
    reportAdditionalData,
  },
  created() {
    if (typeof this.value.headerImg != "undefined") {
      this.imageSource = this.value.headerImg.imgURL;
    }
  },
  computed: {
    include() {
      let include = {};
      if (typeof this.reportTemplatevalue["include"] != "undefined") {
        for (let [key, val] of Object.entries(
            this.reportTemplatevalue["include"]
        )) {
          let value = 0;
          if (val == "true") {
            value = 1;
          }
          include[key] = value;
        }
      }
      return include;
    },
    reportTemplatevalue() {
      return this.value;
    },
    pairArray: {
      get() {
        let discFields = [];
        if (typeof this.reportTemplatevalue["discFields"] != "undefined") {
          discFields = this.reportTemplatevalue["discFields"];
        }
        return discFields;
      },
      set(newVal) {
        this.$set(this.reportTemplatevalue, "discFields", newVal);
        this.$emit("input", this.reportTemplatevalue);
      },
    },
    headTitle: {
      get() {
        let headTitle = "";
        if (typeof this.reportTemplatevalue["headTitle"] != "undefined") {
          headTitle = this.reportTemplatevalue["headTitle"];
        }
        return headTitle;
      },
      set(newVal) {
        this.$set(this.reportTemplatevalue, "headTitle", newVal);
        this.$emit("input", this.reportTemplatevalue);
      },
    },
    timeformat: {
      get() {
        let timeformat = "DD.MM.YYYY HH:mm:ss";
        if (typeof this.reportTemplatevalue["timeformat"] != "undefined") {
          timeformat = this.reportTemplatevalue["timeformat"];
        }
        return timeformat;
      },
      set(newVal) {
        this.$set(this.reportTemplatevalue, "timeformat", newVal);
        this.$emit("input", this.reportTemplatevalue);
      },
    },
    fileClasses() {
      let validateClass = " required";
      if (
          this.result.additionlFormAtts["data-callingFunction"] ==
          "getInsertedFieldsEdit"
      ) {
        validateClass = "";
      }
      return "fileUpload" + validateClass;
    },
  },
  methods: {
    updateInclude(name, newVal) {
      let value = "false";
      if (newVal == 1) {
        value = "true";
      }
      this.$set(this.reportTemplatevalue.include, name, value);
      this.$emit("input", this.reportTemplatevalue);
    },
    checkInput(newVal) {
      this.$set(this.reportTemplatevalue, "discFields", newVal);
      this.$emit("input", this.reportTemplatevalue);
    },
    //Preview the image before upload!
    readURL(file) {
      // console.log(file);
      this.imageSource = URL.createObjectURL(file);
    },

    validateImage() {
      this.imageSource = "";
      //console.log(file);
      var file = this.file;
      if (typeof file != "undefined") {
        if (file.size > 10000000) {
          this.message = "File is too large!";
          this.showDialog = true;
          this.file = null;
        } else if (
            file.type != "image/png" &&
            file.type != "image/jpg" &&
            file.type != "image/jpeg"
        ) {
          this.message = "File doesn't match png or jpg";
          this.showDialog = true;
          this.file = null;
        } else {
          var data = new FormData();
          data.append("file", file);
          data.append("function", "uploadImageCheck");
          let caller = this;
          requests.frameworkAxiosRequest({
            method: 'post',
            url: 'serve.php?f=configuration&f2=reportTemplates',
            data: data,
          })
              .then(function (response) {
                let data = response.data.result.json;
                if (typeof caller.reportTemplatevalue.headerImg == "undefined") {
                  caller.$set(caller.reportTemplatevalue, "headerImg", {});
                }
                caller.$set(
                    caller.reportTemplatevalue.headerImg,
                    "status",
                    "fail"
                );
                caller.$emit("input", caller.reportTemplatevalue);
                if (typeof data.error === "undefined") {
                  if (data.result.result == "success") {
                    caller.$set(
                        caller.reportTemplatevalue.headerImg,
                        "status",
                        "success"
                    );
                    caller.$emit("input", caller.reportTemplatevalue);
                    caller.readURL(file);
                    caller.$emit("file-upload", "headerImageSrc", caller.file);
                  } else {
                    caller.message = data.result.code;
                    caller.showDialog = true;
                    caller.file = null;
                  }
                } else {
                  caller.file = null;
                }
              })
              .catch(function (response) {
                //handle error
                console.log(response);
              });
        }
      }
    },
  },
};
</script>